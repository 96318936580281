// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: "273164b0-1ef8-4304-984a-37c0641fe8e4",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["api://273164b0-1ef8-4304-984a-37c0641fe8e4/user_impersonation"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};