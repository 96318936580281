import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

export default function wrapWithProvider({ element }) {
    return (
        <MsalProvider instance={msalInstance}>
            {element}
        </MsalProvider>
    );
}